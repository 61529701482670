import './styles/landing.scss';
import './libs/jscheck';
const optionOne = document.getElementById('option-1');
const optionTwo = document.getElementById('option-2');
const docBody = document.getElementById('wrapper');
const q = document.getElementById('question');
const feedback = document.querySelector('#feedback main section h2');
fetch('https://codemotion.reactive-labs.io/questions')
    .then(r => r.json())
    .then(r => r.questions)
    .then(r => {
    r.map((item) => {
        if (item.active) {
            const content = item.label.split('^');
            if (typeof content[0] !== typeof undefined) {
                q.innerHTML = content[0];
            }
            else {
                q.innerHTML = 'Something went wrong, sorry. Live questions not avalaible, please try to reload.';
            }
            if (typeof content[1] !== typeof undefined) {
                feedback.innerHTML = content[1];
            }
            else {
                feedback.innerHTML = 'Thanks for having voted';
            }
            if (typeof content[2] !== typeof undefined) {
                docBody.classList.add(content[2]);
            }
            optionOne.children[0].innerHTML = item.answers[0];
            optionTwo.children[0].innerHTML = item.answers[1];
        }
    });
})
    .catch(e => {
    q.innerHTML = 'Something went wrong, sorry. Live questions not avalaible, please try to reload.';
});
/*************************************************
 * Active e deactive DOM elements by click
 *************************************************/
const sendAnswer = (answer) => {
    const url = 'https://codemotion.reactive-labs.io/events';
    fetch(url, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify(answer)
    });
};
optionOne.addEventListener('click', () => {
    if (docBody.className === 'vote-2') {
        docBody.classList.remove('vote-2');
        docBody.classList.add('voted');
        docBody.classList.add('vote-1');
    }
    else {
        docBody.classList.add('voted');
        docBody.classList.add('vote-1');
    }
    const answer = {
        "label": "answer",
        "source": "landing",
        "data": {
            "action": "answer",
            "value": "0"
        }
    };
    sendAnswer(answer);
});
optionTwo.addEventListener('click', () => {
    if (docBody.className === 'vote-1') {
        docBody.classList.remove('vote-1');
        docBody.classList.add('voted');
        docBody.classList.add('vote-2');
    }
    else {
        docBody.classList.add('voted');
        docBody.classList.add('vote-2');
    }
    const answer = {
        "label": "answer",
        "source": "landing",
        "data": {
            "action": "answer",
            "value": "1"
        }
    };
    sendAnswer(answer);
});
